var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "settings-selector",
      class: _vm.disabled ? "settings-selector--disabled" : null,
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            disabled: _vm.disabled,
            dark: "",
            "content-class": "settings-selector__content",
            "offset-y": "",
            "close-on-content-click": !_vm.disabledSelect,
            "nudge-top": 4,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var value = ref.value
                  return [
                    _c(
                      "v-list-tile",
                      _vm._g({}, on),
                      [
                        _c(
                          "v-list-tile-title",
                          { staticClass: "settings-selector__input" },
                          [
                            _vm._t(
                              "selected-item",
                              function () {
                                return [
                                  _vm.defaultSvgIcon
                                    ? _c("SvgIcon", {
                                        staticClass: "settings-selector__icon",
                                        class: {
                                          "settings-selector__icon--active":
                                            _vm.show,
                                        },
                                        attrs: {
                                          size: "small",
                                          name: _vm.defaultSvgIcon,
                                        },
                                      })
                                    : _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "settings-selector__icon",
                                          class: {
                                            "settings-selector__icon--active":
                                              _vm.show,
                                          },
                                          staticStyle: {
                                            "font-size": "14px",
                                            margin: "0 2px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.defaultIcon) + " "
                                          ),
                                        ]
                                      ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "settings-selector__title",
                                      class: {
                                        "settings-selector__title--active":
                                          _vm.show,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedItem.name ||
                                            _vm.defaultTitle
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              { item: _vm.selectedItem }
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "settings-selector__icon",
                                class: {
                                  "settings-selector__icon--active": _vm.show,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.show
                                        ? "arrow_drop_up"
                                        : "arrow_drop_down"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-list",
            { staticStyle: { color: "white", background: "#393b42" } },
            [
              _vm._t("default", function () {
                return [
                  _c("div", { staticClass: "separator" }),
                  _vm._l(_vm.items, function (item, index) {
                    return _c(
                      "v-list-tile",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            !_vm.disabledSelect &&
                              _vm.callAction(item[_vm.selectedProp])
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-tile-title",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _vm._t(
                              "item",
                              function () {
                                return [
                                  item.svgIcon
                                    ? _c("SvgIcon", {
                                        staticClass: "mr-1",
                                        style: _vm.createIconStyles(item),
                                        attrs: {
                                          width: "13",
                                          height: "13",
                                          name: item.svgIcon,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        color: item.iconColor,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                                  ),
                                  _vm._v(" " + _vm._s(item.name) + " "),
                                  _vm._t("append-item", null, {
                                    item: item,
                                    callAction: _vm.callAction,
                                  }),
                                ]
                              },
                              { item: item }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }