<template>
  <div
    class="settings-selector"
    :class="disabled ? 'settings-selector--disabled' : null"
  >
    <v-menu
      :disabled="disabled"
      v-model="show"
      dark
      content-class="settings-selector__content"
      offset-y
      :close-on-content-click="!disabledSelect"
      :nudge-top="4"
    >
      <template v-slot:activator="{ on, value }">
        <v-list-tile v-on="on">
          <v-list-tile-title class="settings-selector__input">
            <slot name="selected-item" :item="selectedItem">
              <SvgIcon
                v-if="defaultSvgIcon"
                class="settings-selector__icon"
                :class="{ 'settings-selector__icon--active': show }"
                size="small"
                :name="defaultSvgIcon"
              />
              <v-icon
                class="settings-selector__icon"
                :class="{ 'settings-selector__icon--active': show }"
                style="font-size: 14px; margin: 0 2px"
                v-else
              >
                {{ defaultIcon }}
              </v-icon>
              <span
                class="settings-selector__title"
                :class="{ 'settings-selector__title--active': show }"
                >{{ selectedItem.name || defaultTitle }}
              </span>
            </slot>
            <v-icon
              class="settings-selector__icon"
              :class="{ 'settings-selector__icon--active': show }"
            >
              {{ show ? "arrow_drop_up" : "arrow_drop_down" }}
            </v-icon>
          </v-list-tile-title>
        </v-list-tile>
      </template>
      <v-list style="color: white; background: #393b42">
        <slot>
          <div class="separator" />
          <v-list-tile
            v-for="(item, index) in items"
            @click="!disabledSelect && callAction(item[selectedProp])"
            :key="index"
          >
            <v-list-tile-title style="display: flex; align-items: center">
              <slot name="item" :item="item">
                <SvgIcon
                  v-if="item.svgIcon"
                  class="mr-1"
                  width="13"
                  height="13"
                  :style="createIconStyles(item)"
                  :name="item.svgIcon"
                />
                <v-icon small :color="item.iconColor">
                  {{ item.icon }}
                </v-icon>
                {{ item.name }}
                <slot
                  name="append-item"
                  :item="item"
                  :callAction="callAction"
                />
              </slot>
            </v-list-tile-title>
          </v-list-tile>
        </slot>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "SettingsSelector",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    disabled: Boolean,
    items: {
      type: Array
    },
    defaultIcon: {
      type: String
    },
    defaultSvgIcon: {
      type: String
    },
    defaultTitle: {
      type: String
    },
    selectedProp: {
      type: String,
      default: "value"
    },
    value: {
      type: [String, Number]
    },
    disabledSelect: {
      type: Boolean
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    defaultItem() {
      return {
        name: this.defaultTitle,
        svgIcon: this.defaultSvgIcon
      }
    },
    selectedItem() {
      return (
        this.items?.find(item => item[this.selectedProp] === this.value) ||
        this.defaultItem
      )
    }
  },
  methods: {
    callAction(action) {
      this.$emit("input", action)
    },
    createIconStyles(item) {
      const styles = {}
      if (item.iconColor) {
        styles.color = item.iconColor
      }
      return styles
    }
  }
}
</script>
<style lang="scss">
.settings-selector {
  &--disabled {
    opacity: 0.5;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .svg-icon--size--small {
    margin: 0 4px 0 0;
  }

  &__icon {
    font-size: 14px;
    color: #798294 !important;
    &--active {
      color: white !important;
    }
  }

  &__title {
    color: #798294;
    font-weight: bold;
    font-size: 14px;

    &--active {
      color: white;
    }
  }

  &__content {
    background-color: #383b41;

    .separator {
      height: 1px;
      background: rgba(255, 255, 255, 0.11);
      margin: 0 auto;
      width: 90%;
    }

    .v-list {
      &__tile {
        padding-left: 7px;
        margin: 5px 10px;
        color: #747d88;

        &__title {
          height: 30px;
          line-height: 30px;
        }

        &--link:hover {
          background: #44464c;
          border-radius: 3px;
          color: white;
        }
      }
    }
  }

  .v-list {
    &__tile {
      padding: 0;

      height: 27px;
    }

    &__tile__title {
      background: #393b42;
      box-shadow: inset 0 1px 88px rgba(255, 255, 255, 0.04);
      border-radius: 4px;
      padding: 0 5px;
      height: 27px;
    }
  }
}
</style>
